* {
  margin: 0;
  padding: 0;
  font-family: "DM Serif Display", serif;
  font-family: "Playfair Display", serif;
  font-optical-sizing: auto;
}

h1 {
  margin-block-start: 0;
  margin-block-end: 0;
}

html {
  background: white;
  color: rgb(16, 8, 0);
}

.hidden {
  visibility: hidden;
}

.row {
  display: flex;
}

.column {
  display: flex;
  flex-direction: column;
}

.f1 {
  flex: 1;
}


.f2 {
  flex: 2;
}

.bg-wrap {
  overflow: hidden;
  width: 55%;
}

.column .bg-wrap {
  height: 100%;
  width: 100%;
}

.bg {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  transition: all 1s;
}

.bg-wrap .bg.pic {
  width: 100%;
  height: 100%;
}

.bg-wrap .bg:hover {
  transform: scale(1.05);
}

.Hero {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  min-height: 100vh;
  width: 100%;
}

.Hero h1 {
  flex-grow: 1;
  display: flex;
  align-items: center;
  font-size: clamp(16px, 20vw, 128px);
}

.Hero div {
  flex-grow: 1;
  display: flex;
  align-items: center;
}

.Hero img {
  max-width: 740px;
  height: auto;
  margin: 16px;
  width: calc(100% - 32px);
  object-fit: contain;
  background-color: rgba(0, 0, 0, 0.8);
}

.Hero span {
  font-size: clamp(10px, 4vw, 48px);
  margin-bottom: 16px;
  padding: 8px;
  background-color: rgba(0, 0, 0, 0.8);
  color: white;
}

.picture {

}

.section {
  display: flex;
  min-height: calc(100dvh - 40px);
  margin-top: 20px;
  margin-bottom: 20px;
}

.section.section-reverse {
  flex-direction: row-reverse;
}

/* .section > div {
  flex: 1;
} */

.section .pictures {
  width: 55%
}

.section .text {
  width: 45%
}

@media only screen and (max-width: 766px) {
  .section {
    min-height: unset;
  }
  .section,
  .section.section-reverse {
    display: flex;
    flex-direction: column;
  }
  .section .pictures {
    width: 100%
  }

  .section .text {
    width: 100%
  }

  .bg-wrap {
    width: calc(100% - 32px);
    margin: 16px;
    /* min-height: 300px; */
  }


  .column .bg-wrap {
    width: unset;
  }

  .pic {
    /* margin: 16px;
    width: 100%; */
    min-height: 300px;
  }

  .image-next-to-image {
    padding-top: 32px;
  }

  .image-next-to-image2 {
    padding-bottom: 32px;
  }

}

.section .text {
  display: flex;
  align-items: center;
}

.section .text-border {
  margin: 32px;
  display: flex;
  flex-direction: column;
  gap: 16px;
  align-items: flex-end;
}

.section.section-reverse .text-border {
  align-items: flex-start;
}

.section .text-border h1 {
  text-align: right;
  font-size: clamp(22px, 4vw, 64px);
}

.section .text-border h2 {
  text-align: right;
  font-size: clamp(20px, 3.5vw, 32px);
}


.section.section-reverse .text-border h1  {
  text-align: left;
}


.section.section-reverse .text-border h2  {
  text-align: left;
}

.section .text-border .running-text {
  text-align: left;
  width: 75%;
  font-size: 18px;
  line-height: 2;
}


@media only screen and (max-width: 1024px) {
  .section .text-border .running-text {
    width: 100%;
  }
}

.przykladowy-apartament{
  background: white;
}

#s9, #s10 {
  background-color: white;
  color: #000;
}

#s9 .text-border{
  height: 100%;
}

#s9 .text-border .running-text {
  flex: 1;
  width: 100%;
}


/* Image next to image */

.image-next-to-image {
  height: unset;
  min-height: unset;
  padding-top: 10dvh;
}
.image-next-to-image2{
  padding-bottom: 10dvh;
}

.image-next-to-image img {
  margin: 8px;
  width: calc(100% - 16px);
  max-height: 450px;
  object-fit: contain;
  height: auto;
}

/* Vinci Section */

.section.vinci-section {
  flex-direction: column;
  padding: 32px;
  align-items: center;
  background-color: white;
  color: #000;
  min-height: calc( 100dvh - 64px );
  gap: 32px;
}

.vinci-logo {
  max-width: 400px;
  width: 100%;
  object-fit: contain;
  height: auto;
}

.section.vinci-section .running-text {
  line-height: 1.5;
  text-align: justify;
  margin-bottom: 64px;
}

/* inwestycja */


.inwestycje {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 32px;
  flex-wrap: wrap;
}

.inwestycja-container {
  display: flex;
  flex-direction: column;
  gap: 8px;
  width: 100%;
  min-width: 175px;
  max-width: 240px;
}

.inwestycja {
  display: flex;
  flex-direction: column;
  min-height: 300px;
  position: relative;
  cursor: pointer;
}

.inwestycja-container:hover > .inwestycja {
  filter: brightness(0.75);
}

.inwestycja-pic {
  flex-grow: 1;
  background-size: cover;
  background-position: center;
}

.inwestycja-name {
  min-height: 34px;
  position: absolute;
  bottom: 0;
  background: rgba(0, 0, 0, 0.7);
  color: white;
  padding: 8px;
  text-align: center;
  width: calc( 100% - 16px );
}

.inwestycja-zobacz {
  color: white;
  text-align: left;
  background: #e10024 url("pictures/readmore_ico.png") no-repeat 90% center;
  padding: 12px 10px 10px 30px;
  box-shadow: 0 15px 30px rgba(0, 0, 0, 0.3);
  border-radius: 30px;
  font-size: 18px;
  border-top-right-radius: 0px !important;
  outline: 0;
  border: 0;
  transition: all 0.2s;
  cursor: pointer;
  text-decoration: none;
}

/* Contact */
.contact {
  display: block;
  height: 86px;
  width: 50px;
  background-color: #e10024;
  position: fixed;
  bottom: 16px;
  right: 16px;
  padding: 8px;
  border-radius: 50px;
  cursor: pointer;
  padding-top: 14px;
  text-align: center;
  font-size: 15px;
  font-weight: 600;
  color: white;
  transition: all 2s;
  z-index: 10;
}

.contact:hover {
  background-color: #6e0014;
}

.contact svg {
  fill: white;
}

@media only screen and (min-width: 766px) {
  .contact.zoom {
    transform: scale(1.4);
    bottom: 26px;
    right: 22px;
  }
}

.contact.zoom:hover {
  -webkit-animation-play-state: paused;
  -webkit-animation-play-state: running;
}


/* Biuro */

.section-biuro {
  display: flex;
  gap: 16px;
  margin-top: 64px;
  margin-bottom: 64px;
}

.section-biuro .text-border {
  display: flex;
  flex-direction: column;
  gap: 40px;
  padding: 16px 32px;
}


.section-biuro .text-border h1 {
  text-align: right;
  font-size: clamp(22px, 4vw, 64px);
}

.section-biuro .formularz-wrap {
  padding: 0 32px 0 0;
  flex: 1;
}

.section-biuro .text {
  flex: 1;
}

.section-biuro .text-border .running-text {
  width: 100%;
  text-align: left;
  font-size: 18px;
  line-height: 1.5;
}

.section-biuro .text-border h2 {
  margin-bottom: 4px;
}

.section-biuro .map-wrap {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 16px;
}


.section-biuro iframe{
  width: 100%;
}

.section-biuro .text .text-border {
  align-items: flex-start;
}

.section-biuro .text .text-border .running-text{
  text-align: left;
}

.section-biuro .text h1,
.section-biuro .text h2 {
  text-align: left;
}

.sprzedawca{
  display: flex;
  gap: 16px
}
.sprzedawca-foto{
  max-width: 140px;
  object-fit: contain;
  object-position: top;
  height: auto;
}

@media only screen and (max-width: 766px) {

  .section-biuro {
    flex-direction: column;
  }


  .section-biuro .formularz-wrap {
    padding: 32px;
  }


  .sprzedawca-foto {
    max-width: 90px;
    max-height: 50dvh;
  }

  /* .section-biuro .map-wrap,
  .section-biuro .text {
    width: 100%;
  }
  .section-biuro .map-wrap {
    padding: 0;
  }
  .section-biuro .text .text-border {
    align-items: center;
    width: 100%;
  }
  .section-biuro .map-wrap iframe{
    width: 100%;
    height: 40dvh;
  }
*/
}


@media only screen and (max-width: 450px) {
  .sprzedawca {
    flex-direction: column;
  }
  .section-biuro .formularz-wrap {
    padding: 16px;
  }

}




/* Formularz */

.formularz {
  display: flex;
  flex-direction: column;
  gap: 16px;
  width: 100%;
}

.formularz label {
  font-size: 18px;
}

.formularz input {
  width: calc(100% - 32px);
  padding: 2px 16px;
  height: 32px;
}

.formularz textarea {
  width: calc(100% - 32px);
  padding: 8px 16px ;
  height: 188px;
  resize: none;
}

.formularz input,
.formularz textarea  {
  border: 1px solid #ced4da;
  box-shadow: 0 0 40px 5px rgba(51, 51, 51, 0.1);
  border-radius: 20px;
  font-size: 17px;
  line-height: 1.5;
}

.formularz .zgody * {
  font-size: 14px!important;
}

.formularz .zgody {
  line-height: 1.25;
}

.formularz .zgody {

}

.formularz a {
  text-decoration: underline;
  color: blue
}


.formularz .zgody label {
  display: flex;
  align-items: center;
  gap: 8px;
  cursor: pointer;
  margin-top: 8px;
}

.formularz .zgody > p {
  margin-top: 8px;
}

.formularz .zgody label input {
  width: 16px;
  height: 16px;
  flex-shrink: 0;
}

.formularz button {
  color: white;
  text-align: left;
  background: #e10024 url("pictures/readmore_ico.png") no-repeat 90% center;
  padding: 12px 10px 10px 30px;
  box-shadow: 0 15px 30px rgba(0, 0, 0, 0.3);
  border-radius: 30px;
  font-size: 18px;
  border-top-right-radius: 0px !important;
  outline: 0;
  border: 0;
  transition: all 0.2s;
  cursor: pointer;
}

.formularz button:hover {
  filter: brightness(0.75);
}

.formularz button.disabled {
  cursor: unset;
  filter: brightness(0.6);
  pointer-events: none;
}



.formularz.sent{
  position: relative;
}


.formularz-sent-overlay{
  width: calc( 100% + 16px ) ;
  height: calc( 100% + 16px ) ;
  top: -8px;
  left: -8px;
  position: absolute;
  background: rgba(138, 167, 204, 0.7);
  z-index: 2;
  border-radius: 20px;
  font-size: 40px;
  font-weight: 700;
  display: flex;
  justify-content: center;
  align-content: center;
  align-items: center;
}

/* polityka-prywatnosci */

.polityka-prywatnosci-modal {
  align-content: center;
}

.polityka-prywatnosci-container {
  background: white;
  width: calc( 90vw - 64px);
  height: calc( 90dvh - 64px);
  margin: auto;
  padding: 36px 16px 16px 32px;
  border-radius: 16px;
  position: relative;
  outline: none!important;
}

.polityka-prywatnosci {
  overflow: auto;
  height: 100%;
  line-height: 2;
  padding-right: 8px;
}


.polityka-prywatnosci-container button {
  position: absolute;
  top: 6px;
  right: 6px;
  width: 30px;
  height: 30px;
  background: transparent;
  border: 0;
  border-radius: 16px;
  fill: red;
}

.polityka-prywatnosci-container button:hover {
  background-color: #8080806b;
  cursor: pointer;
}



.polityka-prywatnosci-container dl,
.polityka-prywatnosci-container  ol,
.polityka-prywatnosci-container ul {
  margin-top: 0;
  margin-bottom: 1rem;
}

.polityka-prywatnosci-container  ol,
.polityka-prywatnosci-container ul {
  padding-left: 2rem;
}