

#tlo2 {
  background-image: url("pictures/LAT_BRU_PW_kam2_02_LR_srgb8bit_wide.jpg");
  background-image: url("pictures/LAT_BRU_PW_kam2_02_LR_srgb8bit_wide-70.webp");
  /* background-repeat: no-repeat; */
  background-size: cover;
  transition: all 1s;
}

#p2 {
  background-image: url("pictures/s2.jpg");
}

#p4 {
  background-image: url("pictures/pola-mapa.png");
}

#p5 {
  background-image: url("pictures/rower-z-pieskiem.png");
}

/* #p6 {
  background-image: url("pictures/blok.png");
} */

/* #p7 {
  background-image: url("pictures/blok2.png");
} */

/* #p8 {
  background-image: url("pictures/budynek0.png");
} */


#p10 {
  background-image: url("pictures/budynek1.jpg");
}

#p11 {
  background-image: url("pictures/powoz-konie.jpg");
}

#p12 {
  background-image: url("pictures/drzewo.webp");
}

#p13 {
  background-image: url("pictures/rzot-z-gory.jpg");
  background-size: contain;
  margin-right: 16px;
}

#p14 {
  background-image: url("pictures/mieszkanie1.jpg");
  background-position: left;
}

#p16 {
  background-image: url("pictures/przykładowy-1.png");

  width: 100%;
  background-size: contain;
}

#p18 {
  background-image: url("pictures/mieszkanie2.jpg");
  background-size: contain;
  width: 100%;
  max-width: 60%;
}


#p19 {
  background-image: url("pictures/przykładowy-25.png");
  width: 100%;
  background-size: contain;
}


#p20 {
  background-image: url("pictures/bruna-1.jpg");
  background-image: url("pictures/LAT_BRU_PW_kam1_02_LR_srgb8bit.webp");
  /* min-height: 100dvh;
  width: 100%;
  background-size: contain; */
}


#p21 {
  background-image: url("pictures/projekt-zieleni.jpg");
  background-image: url("pictures/projekt-zieleni.webp");
}


#p22 {
  background-image: url("pictures/komfort-zycia.jpg");
}



#p23 {
  background-image: url("pictures/premium-fitness.jpg");
  background-image: url("pictures/premium-fitness.webp");
}


#p24 {
  background-image: url("pictures/BRUNA7-FOLDER-V-2024-Zb2.jpg");
}
